<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Comprobante</td>
            <td>
              <v-file-input
                class="mt-5"
                label="Comprobante"
                required
                outlined
                dense
                show-size
                v-model="file"
              ></v-file-input>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Tipo de comprobante:</td>
            <td>
              <v-autocomplete
                v-model="tipo_comprobante"
                :items="listTipoFactura"
                label="Seleccionar..."
                hide-no-data
                hide-selected
              ></v-autocomplete>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Punto de Venta:</td>
            <td>
              <v-text-field v-model="punto_venta"></v-text-field>
            </td>
          </tr>
          <tr>
            <td>Número de comprobante:</td>
            <td>
              <v-text-field v-model="nro_comprobante" type="number"></v-text-field>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Fecha Comprobante:</td>
            <td>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fecha_comprobante"
                    label=""
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fecha_comprobante" @input="menu1 = false"></v-date-picker>
              </v-menu>
            </td>
          </tr>
          <tr>
            <td>Concepto:</td>
            <td>
              <v-text-field v-model="concepto"></v-text-field>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="notranslate">Monto:</td>
            <td>
              <v-text-field-money
                v-model="monto"
                v-bind:properties="{
                  prefix: '$',
                  readonly: false,
                  outlined: false,
                  clearable: true,
                  placeholder: '',
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  length: 15,
                  precision: 2,
                  empty: null,
                }"
                class="notranslate"
              ></v-text-field-money>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
      :disabled="!monto || !file || !nro_comprobante || !punto_venta || !concepto"
      block
      color="primary"
      @click="uploadFile()"
      :loading="loading"
    >
      Cargar comprobante
    </v-btn>
  </div>
</template>
<script>
import { API_URL, TIPO_FACTURA } from '@/common/config';

const axios = require('axios');

export default {
  props: ['estado', 'ParametrosCliente'],
  data: () => ({
    file: '',
    tipo_comprobante: '',
    punto_venta: '',
    nro_comprobante: '',
    fecha_comprobante: '',
    concepto: '',
    monto: '',
    menu1: false,
    listTipoFactura: [],
    loading: false,
    token: localStorage.getItem('token'),
    rules: {
      number: [(val) => Number.isNaN(val) || 'El valor igresado no es un número'],
    },
  }),
  methods: {
    async uploadFile() {
      this.loading = true;
      const url = `${API_URL}api/comprobanteCliente/nuevo`;
      const header = {
        headers: {
          token: this.token,
        },
      };
      const data = new FormData();
      data.append('lineacreditocliente_id', Number(this.$route.params.solicitud_id));
      data.append('punto_venta', this.punto_venta);
      data.append('nro_comprobante', this.nro_comprobante);
      data.append('fecha', this.fecha_comprobante);
      data.append('concepto', this.concepto);
      data.append('tipo_comprobante', this.tipo_comprobante);
      data.append('monto', this.monto);
      data.append('file', this.file);
      const {
        data: { status, message },
      } = await axios.post(url, data, header);
      if (status === 'error') {
        await this.$swal.fire({
          position: 'center',
          icon: 'error',
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (status === 'success') {
        await this.$swal.fire({
          position: 'center',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.loading = false;
      this.$router.go(0);
    },
  },
  created() {
    this.listTipoFactura = TIPO_FACTURA;
  },
};
</script>
