var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("Comprobante")]),_c('td',[_c('v-file-input',{staticClass:"mt-5",attrs:{"label":"Comprobante","required":"","outlined":"","dense":"","show-size":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('td')]),_c('tr',[_c('td',[_vm._v("Tipo de comprobante:")]),_c('td',[_c('v-autocomplete',{attrs:{"items":_vm.listTipoFactura,"label":"Seleccionar...","hide-no-data":"","hide-selected":""},model:{value:(_vm.tipo_comprobante),callback:function ($$v) {_vm.tipo_comprobante=$$v},expression:"tipo_comprobante"}})],1),_c('td')]),_c('tr',[_c('td',[_vm._v("Punto de Venta:")]),_c('td',[_c('v-text-field',{model:{value:(_vm.punto_venta),callback:function ($$v) {_vm.punto_venta=$$v},expression:"punto_venta"}})],1)]),_c('tr',[_c('td',[_vm._v("Número de comprobante:")]),_c('td',[_c('v-text-field',{attrs:{"type":"number"},model:{value:(_vm.nro_comprobante),callback:function ($$v) {_vm.nro_comprobante=$$v},expression:"nro_comprobante"}})],1),_c('td')]),_c('tr',[_c('td',[_vm._v("Fecha Comprobante:")]),_c('td',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fecha_comprobante),callback:function ($$v) {_vm.fecha_comprobante=$$v},expression:"fecha_comprobante"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.fecha_comprobante),callback:function ($$v) {_vm.fecha_comprobante=$$v},expression:"fecha_comprobante"}})],1)],1)]),_c('tr',[_c('td',[_vm._v("Concepto:")]),_c('td',[_c('v-text-field',{model:{value:(_vm.concepto),callback:function ($$v) {_vm.concepto=$$v},expression:"concepto"}})],1),_c('td')]),_c('tr',[_c('td',{staticClass:"notranslate"},[_vm._v("Monto:")]),_c('td',[_c('v-text-field-money',{staticClass:"notranslate",attrs:{"properties":{
                prefix: '$',
                readonly: false,
                outlined: false,
                clearable: true,
                placeholder: '',
              },"options":{
                locale: 'es-AR',
                length: 15,
                precision: 2,
                empty: null,
              }},model:{value:(_vm.monto),callback:function ($$v) {_vm.monto=$$v},expression:"monto"}})],1)])])]},proxy:true}])}),_c('v-btn',{attrs:{"disabled":!_vm.monto || !_vm.file || !_vm.nro_comprobante || !_vm.punto_venta || !_vm.concepto,"block":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.uploadFile()}}},[_vm._v(" Cargar comprobante ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }